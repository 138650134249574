import "simplebar/dist/simplebar.min.js";
import { Sidebar, Toast } from "@coreui/coreui-pro";
import * as Turbo from "@hotwired/turbo";

// Turboを無効化して必要なelementにdata-turbo="true"を追加する
Turbo.session.drive = false;

// 共通初期化関数
const initialize = () => {
  setupSidebarToggle();
  showFlashToasts();
};

// メイン関数
const main = () => {
  document.addEventListener("turbo:load", initialize);
  document.addEventListener("turbo:render", initialize);
};
main();

/**
 * navigationの開閉
 * @returns
 */
function setupSidebarToggle() {
  const headerTogglerButton = document.getElementById("headerTogglerButton");
  if (!headerTogglerButton) return;

  headerTogglerButton.addEventListener("click", function () {
    const sidebarElement = document.querySelector("#sidebar");
    if (sidebarElement) {
      const sidebar = Sidebar.getInstance(sidebarElement);
      sidebar.toggle();
    }
  });
}

/**
 * Flashのトースト表示
 */
export function showFlashToasts() {
  const flashToasts = document.querySelectorAll(".flash-toast");
  flashToasts.forEach((toastElement) => {
    const toast = Toast.getOrCreateInstance(toastElement);
    toast.show();

    // トーストが隠れた後に削除
    toastElement.addEventListener("hidden.coreui.toast", () => {
      toastElement.remove();
    });
  });
}
